import React from 'react';

const InforStream = ({ stream, onClose }) => {
    const renderFormattedDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-lg">
                <div className="text-black">
                    <h2 className="text-[24px] font-bold text-center mb-4">THÔNG TIN STREAM</h2>
                    <p className="mb-2"><strong>Video ID:</strong> {stream.VideoID}</p>
                    <p className="mb-2"><strong>Đường dẫn:</strong> {stream.VideoLocation}</p>
                    <p className="mb-2"><strong>URL:</strong> {stream.PlayURL}</p>
                    <p className="mb-2"><strong>Thời gian bắt đầu:</strong> {renderFormattedDateTime(stream.StartAt)}</p>
                    <p className="mb-2"><strong>Thời gian kết thúc:</strong> {renderFormattedDateTime(stream.EndAt)}</p>
                    <div className="flex justify-center">
                        <button
                            className="mt-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
                            onClick={onClose}
                        >
                            ĐÓNG
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InforStream;
