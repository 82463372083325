import "./App.css";
import React from "react";
import Login from "./Pages/Login";
import TableUser from "./Pages/Users/TableUser";
import TableStream from "./Pages/Streams/TableStream";
import CreateUser from "./Pages/Users/CreateUser";
import CreateStream from "./Pages/Streams/CreateStream";
import MonitorStream from "./Pages/Streams/MonitorStream";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { UserProvider, useUser } from "./Context/UserContext";

const ProtectedRoute = () => {
  const { user } = useUser();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/users" element={<TableUser />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/streams" element={<TableStream />} />
            <Route path="/streams/create" element={<CreateStream />} />
            <Route path="/streams/monitor" element={<MonitorStream />} />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;